import React from 'react';
import PageHeader from './PageHeader';

const criteriaData = [
  {
    year: '2020 год',
    categories: [
      {
        title: 'ИИ для оценки образовательных программ',
        criteria: [
          'Точность анализа: Насколько точно система определяет сильные и слабые стороны образовательных программ.',
          'Адаптивность: Способность системы адаптироваться под различные образовательные контексты и программы.',
          'Интерфейс пользователя: Удобство и интуитивность использования системы для преподавателей и администраторов.',
          'Инновационность: Внедрение новых методов и технологий в оценку образовательных программ.',
          'Влияние на качество образования: Потенциальное влияние системы на улучшение образовательных результатов.'
        ]
      },
      {
        title: 'ИИ для анализа медицинских изображений',
        criteria: [
          'Точность диагностики: Насколько точно система определяет и классифицирует медицинские изображения.',
          'Скорость анализа: Время, необходимое для анализа и выдачи результатов.',
          'Интерфейс пользователя: Удобство использования системы для врачей и медицинского персонала.',
          'Интеграция: Способность системы интегрироваться с существующими медицинскими информационными системами.',
          'Безопасность данных: Надежность системы в защите персональных медицинских данных.'
        ]
      },
      {
        title: 'ИИ для управления городским трафиком',
        criteria: [
          'Эффективность управления: Способность системы снижать пробки и улучшать транспортные потоки.',
          'Точность прогнозирования: Точность прогнозирования трафика в реальном времени.',
          'Интерфейс пользователя: Удобство использования системы для городских служб и граждан.',
          'Интеграция: Способность системы работать с различными источниками данных и существующими транспортными системами.',
          'Экологическое воздействие: Влияние системы на снижение выбросов и улучшение экологической обстановки.'
        ]
      }
    ]
  },
  {
    year: '2021 год',
    categories: [
      {
        title: 'Прогнозирование и предотвращение природных пожаров с помощью ИИ',
        criteria: [
          'Точность прогнозирования: Способность системы точно предсказывать места и время возникновения пожаров.',
          'Эффективность предотвращения: Насколько система помогает предотвращать пожары или минимизировать их последствия.',
          'Интерфейс пользователя: Удобство использования системы для служб спасения и лесного хозяйства.',
          'Интеграция: Возможность интеграции с существующими системами мониторинга природных условий.',
          'Влияние на безопасность: Вклад системы в повышение общей безопасности и снижение ущерба от пожаров.'
        ]
      },
      {
        title: 'ИИ для финансового анализа и предсказаний',
        criteria: [
          'Точность предсказаний: Способность системы точно предсказывать финансовые тренды и события.',
          'Скорость анализа: Время, необходимое для обработки данных и выдачи результатов.',
          'Интерфейс пользователя: Удобство использования системы для аналитиков и инвесторов.',
          'Интеграция: Возможность интеграции с финансовыми платформами и системами управления.',
          'Инновационность: Внедрение новых подходов и технологий в финансовый анализ.'
        ]
      },
      {
        title: 'ИИ для мониторинга окружающей среды',
        criteria: [
          'Точность мониторинга: Способность системы точно измерять и анализировать экологические данные.',
          'Широта охвата: Способность системы охватывать различные аспекты окружающей среды (вода, воздух, почва).',
          'Интерфейс пользователя: Удобство использования системы для экологов и государственных служб.',
          'Интеграция: Возможность интеграции с другими системами мониторинга и анализа данных.',
          'Влияние на экологическую политику: Потенциальное влияние системы на принятие решений и улучшение экологической обстановки.'
        ]
      }
    ]
  },
  {
    year: '2022 год',
    categories: [
      {
        title: 'ИИ на страже качества российских товаров',
        criteria: [
          'Точность контроля: Способность системы точно определять качество товаров.',
          'Скорость анализа: Время, необходимое для проверки качества продукции.',
          'Интерфейс пользователя: Удобство использования системы для производителей и контролирующих органов.',
          'Интеграция: Возможность интеграции с производственными системами и базами данных.',
          'Влияние на качество продукции: Потенциальное влияние системы на улучшение качества российских товаров.'
        ]
      },
      {
        title: 'ИИ для медицинской диагностики',
        criteria: [
          'Точность диагностики: Способность системы точно определять заболевания и аномалии.',
          'Скорость анализа: Время, необходимое для постановки диагноза.',
          'Интерфейс пользователя: Удобство использования системы для врачей и медицинского персонала.',
          'Интеграция: Возможность интеграции с медицинскими информационными системами и оборудованием.',
          'Влияние на медицинские услуги: Вклад системы в улучшение диагностики и лечения пациентов.'
        ]
      },
      {
        title: 'ИИ для предсказания и предотвращения кибератак',
        criteria: [
          'Точность предсказания: Способность системы точно предсказывать возможные кибератаки.',
          'Эффективность предотвращения: Насколько система помогает предотвращать или минимизировать последствия кибератак.',
          'Интерфейс пользователя: Удобство использования системы для специалистов по кибербезопасности.',
          'Интеграция: Возможность интеграции с существующими системами кибербезопасности.',
          'Влияние на безопасность: Вклад системы в повышение уровня кибербезопасности.'
        ]
      }
    ]
  },
  {
    year: '2023 год',
    categories: [
      {
        title: 'ИИ для оптимизации транспортных потоков',
        criteria: [
          'Эффективность оптимизации: Способность системы снижать заторы и улучшать транспортные потоки.',
          'Точность прогнозирования: Способность системы точно предсказывать изменения в трафике.',
          'Интерфейс пользователя: Удобство использования системы для городских служб и водителей.',
          'Интеграция: Возможность интеграции с транспортными системами и инфраструктурой.',
          'Экологическое воздействие: Влияние системы на снижение выбросов и улучшение экологической обстановки.'
        ]
      },
      {
        title: 'ИИ для прогнозирования экономических трендов',
        criteria: [
          'Точность предсказаний: Способность системы точно предсказывать экономические тренды и события.',
          'Скорость анализа: Время, необходимое для обработки данных и выдачи результатов.',
          'Интерфейс пользователя: Удобство использования системы для аналитиков и экономистов.',
          'Интеграция: Возможность интеграции с финансовыми платформами и базами данных.',
          'Инновационность: Внедрение новых подходов и технологий в экономический анализ.'
        ]
      },
      {
        title: 'ИИ для анализа и управления энергетическими системами',
        criteria: [
          'Точность анализа: Способность системы точно анализировать данные об энергетических системах.',
          'Эффективность управления: Способность системы оптимизировать использование энергетических ресурсов.',
          'Интерфейс пользователя: Удобство использования системы для энергетических компаний и служб.',
          'Интеграция: Возможность интеграции с существующими энергетическими системами.',
          'Экономическое воздействие: Влияние системы на снижение затрат и повышение эффективности энергетических ресурсов.'
        ]
      }
    ]
  },
  {
    year: '2024 год',
    categories: [
      {
        title: 'ИИ для управления водными ресурсами',
        criteria: [
          'Точность анализа: Способность системы точно измерять и анализировать данные о водных ресурсах.',
          'Эффективность управления: Способность системы оптимизировать распределение и использование воды.',
          'Интерфейс пользователя: Удобство использования системы для государственных и частных служб водоснабжения.',
          'Интеграция: Возможность интеграции с существующими системами управления водными ресурсами.',
          'Экологическое воздействие: Влияние системы на улучшение качества и сохранение водных ресурсов.'
        ]
      },
      {
        title: 'ИИ для анализа и предсказания потребительского поведения',
        criteria: [
          'Точность предсказаний: Способность системы точно предсказывать поведение и предпочтения потребителей.',
          'Скорость анализа: Время, необходимое для обработки данных и выдачи результатов.',
          'Интерфейс пользователя: Удобство использования системы для маркетологов и аналитиков.',
          'Интеграция: Возможность интеграции с системами управления взаимоотношениями с клиентами (CRM).',
          'Влияние на бизнес: Вклад системы в улучшение стратегий маркетинга и повышения удовлетворенности клиентов.'
        ]
      },
      {
        title: 'ИИ для мониторинга и улучшения состояния здоровья населения',
        criteria: [
          'Точность мониторинга: Способность системы точно измерять и анализировать данные о здоровье населения.',
          'Эффективность улучшения здоровья: Насколько система способствует улучшению состояния здоровья населения.',
          'Интерфейс пользователя: Удобство использования системы для медицинских служб и населения.',
          'Интеграция: Возможность интеграции с существующими медицинскими информационными системами.',
          'Социальное воздействие: Влияние системы на общее состояние здоровья и качество жизни населения.'
        ]
      }
    ]
  }
];

const Criteria = () => {
  return (
    <div>
      <PageHeader title="Критерии оценки" />
      <div className="criteria-container">
        {/* <div className='criteria-title'>Критерии оценки для номинаций Global IT Challenge</div> */}
        {criteriaData.map((yearData, index) => (
          <div key={index} className="criteria-year">
            <h2>{yearData.year}</h2>
            {yearData.categories.map((category, idx) => (
              <div key={idx} className="criteria-category">
                <h3>{category.title}</h3>
                <ul>
                  {category.criteria.map((criterion, id) => (
                    <li key={id}>{criterion}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Criteria;
