import React from 'react';
import PageHeader from './PageHeader';

const Regulations = () => {
  return (
    <div>
      <PageHeader title="Положение" />
      <div className="regulations-container">
        <h2>Положение о конкурсе Global IT Challenge</h2>
        <div className="regulation-section">
          <h3>1. Общие положения</h3>
          <p>1.1. Конкурс Global IT Challenge (далее - Конкурс) является ежегодным международным соревнованием в области информационных технологий, организованным Global Association of IT Experts (GAITE).</p>
          <p>1.2. Конкурс проводится с целью выявления и поощрения лучших IT-специалистов, стимулирования развития инновационных технологий и укрепления международного сотрудничества в сфере IT.</p>
          <p>1.3. Конкурс проводится в форме хакатона, в котором команды из трех человек, включая капитана, решают предложенные задачи в определенных номинациях.</p>
          <p>1.4 Конкурс проводится ежегодно с 1 по 15 августа, подведение итогов 20 августа.</p>
          <p>1.5 Заявки на конкурс принимаются в период с 1 по 30 июля.</p>
        </div>
        <div className="regulation-section">
          <h3>2. Организаторы и партнеры Конкурса</h3>
          <p>2.1. Организатором Конкурса является Global Association of IT Experts (GAITE).</p>
          <p>2.2. Партнерами Конкурса могут выступать компании и организации, заинтересованные в поддержке и развитии IT-сферы.</p>
        </div>
        <div className="regulation-section">
          <h3>3. Участники Конкурса</h3>
          <p>33.1. Участниками Конкурса могут быть профессиональные IT-специалисты с опытом работы не менее 3-х лет из различных стран, обладающие соответствующими знаниями и навыками.</p>
          <p>3.2. Каждый участник может входить только в одну команду.</p>
          <p>3.3. Участие в Конкурсе бесплатное, однако команды могут нести расходы, связанные с проездом и проживанием.</p>
        </div>
        <div className="regulation-section">
          <h3>4. Порядок проведения Конкурса</h3>
          <p>4.1. Конкурс проводится в несколько этапов:</p>
          <ul>
            <li>Регистрация участников.</li>
            <li>Отборочный этап.</li>
            <li>Основной этап (хакатон).</li>
            <li>Подведение итогов и награждение.</li>
          </ul>
          <p>4.2. Регистрация участников осуществляется на официальном сайте Конкурса.</p>
          <p>4.3. На отборочном этапе проводится предварительная оценка заявок и отбор команд для участия в основном этапе.</p>
          <p>4.4. Основной этап проходит в формате хакатона, где команды решают задачи в рамках предложенных номинаций.</p>
        </div>
        <div className="regulation-section">
          <h3>5. Номинации Конкурса</h3>
          <p>Номинации Конкурса определяются ежегодно. Примеры номинаций:</p>
          <ul>
            <li>ИИ для оценки образовательных программ.</li>
            <li>ИИ для анализа медицинских изображений.</li>
            <li>ИИ для управления городским трафиком.</li>
            <li>Прогнозирование и предотвращение природных пожаров с помощью ИИ.</li>
            <li>ИИ для финансового анализа и предсказаний.</li>
            <li>ИИ для мониторинга окружающей среды.</li>
            <li>ИИ на страже качества российских товаров.</li>
            <li>ИИ для медицинской диагностики.</li>
            <li>ИИ для предсказания и предотвращения кибератак.</li>
            <li>ИИ для оптимизации транспортных потоков.</li>
            <li>ИИ для прогнозирования экономических трендов.</li>
            <li>ИИ для анализа и управления энергетическими системами.</li>
            <li>ИИ для управления водными ресурсами.</li>
            <li>ИИ для анализа и предсказания потребительского поведения.</li>
            <li>ИИ для мониторинга и улучшения состояния здоровья населения.</li>
          </ul>
        </div>
        <div className="regulation-section">
          <h3>6. Критерии оценки</h3>
          <p>6.1. Оценка конкурсных работ производится жюри, состоящим из высококвалифицированных экспертов с международным признанием и опытом работы не менее трех лет.</p>
          <p>6.2. Критерии оценки включают:</p>
          <ul>
            <li>Точность решений.</li>
            <li>Инновационность.</li>
            <li>Практическая применимость.</li>
            <li>Удобство использования.</li>
            <li>Экономическая эффективность.</li>
            <li>Социальное и экологическое воздействие.</li>
          </ul>
        </div>
        <div className="regulation-section">
          <h3>7. Подведение итогов и награждение</h3>
          <p>7.1. По итогам Конкурса определяются победители и призеры в каждой номинации.</p>
          <p>7.2. Победители и призеры награждаются дипломами, медалями и ценными призами от организаторов и партнеров Конкурса.</p>
          <p>7.3. Результаты Конкурса публикуются на официальном сайте GAITE.</p>
        </div>
        <div className="regulation-section">
          <h3>8. Заключительные положения</h3>
          <p>8.1. Настоящее Положение вступает в силу с момента его утверждения организатором Конкурса.</p>
          <p>8.2. Организаторы оставляют за собой право вносить изменения в настоящее Положение.</p>
          <p>8.3. Все спорные вопросы, связанные с проведением Конкурса, решаются организаторами.</p>
        </div>
      </div>
    </div>
  );
};

export default Regulations;
